
import React, { useState, useEffect} from "react";
import '../assets/css/popup.css';

function PopupShare({ onClose, text }) {
    const [showPopupShare, setShowPopupShare] = useState(false);
    const [lastSeenTime, setLastSeenTime] = useState(localStorage.getItem('lastSeenTime'));
    const [popupBannerSeen, setPopupBannerSeen] = useState(localStorage.getItem('popup_bannerSeen') === 'true');

    useEffect(() => {
        console.log(text);
        const openPopupButton = document.getElementById('open-popup_banner');
        const closePopupButton = document.getElementById('close-popup_banner');
        const popupBanner = document.querySelector('.popup_banner');
        const popupBannerContent = document.querySelector('.popup_banner-content');

        if (popupBannerSeen && lastSeenTime) {
            const currentTime = new Date().getTime();
            const sixHours = 6 * 60 * 60 * 1000; // 6 часов в миллисекундах
            if (currentTime - lastSeenTime >= sixHours) {
                setShowPopupShare(true);
            }
        } else {
            setShowPopupShare(true);
        }

        closePopupButton.addEventListener('click', () => {
            popupBannerContent.classList.add('fadeOut');
            setTimeout(() => {
                popupBanner.classList.remove('show');
                popupBannerContent.classList.remove('show', 'fadeOut');
                document.body.style.overflow = 'auto';
                document.body.style.position = 'static';
                document.body.style.top = '';
                document.body.style.left = '';
                onClose();
            }, 200);
        });
    }, [lastSeenTime, popupBannerSeen]);
    return (
        <div class="popup_banner show">
            <div class="popup_banner_dop">
                <div class="popup_banner-content show">
                    <svg id="close-popup_banner" xmlns="http://www.w3.org/2000/svg" version="1.0" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                            <path d="M2315 5109 c-555 -57 -1076 -289 -1478 -657 -933 -855 -1107 -2244 -413 -3302 180 -275 451 -546 726 -726 328 -215 686 -348 1095 -406 118 -17 512 -17 630 0 590 84 1090 329 1494 733 404 404 649 904 733 1494 17 118 17 512 0 630 -84 590 -329 1090 -733 1494 -395 394 -884 639 -1449 726 -126 19 -477 28 -605 14z m430 -380 c961 -82 1758 -794 1949 -1739 196 -967 -291 -1952 -1179 -2386 -828 -404 -1813 -245 -2474 399 -862 840 -879 2213 -38 3076 456 468 1095 706 1742 650z"></path>
                            <path d="M1675 3555 c-71 -25 -125 -106 -125 -185 1 -67 37 -111 388 -462 l346 -348 -346 -347 c-360 -362 -388 -395 -388 -473 0 -43 29 -107 61 -137 32 -29 91 -53 131 -53 76 1 111 30 471 388 l347 346 348 -346 c361 -360 394 -388 472 -388 78 0 159 62 180 138 31 111 21 124 -378 524 l-346 348 350 352 c294 295 354 361 369 400 57 152 -91 300 -243 243 -39 -15 -105 -75 -400 -369 l-352 -350 -348 346 c-356 356 -395 388 -467 387 -16 0 -48 -6 -70 -14z"></path>
                        </g>
                    </svg>
                    <div className="title-share-popup">Поделиться</div>
                    <div className="popup-share-contents">
                        <div className="popup-share-content">
                            <img className="popupShareImg" src="/img/Vk_Logo_1.svg"/>
                            <img className="share-blur" src="/img/Vk_Logo_1.svg"/>
                        </div>
                        <div className="popup-share-content">
                            <img className="popupShareImg" src="/img/yandex.png"/>
                            <img className="share-blur" src="/img/yandex.png"/>
                        </div>
                    </div>
                    <div className="popup-link">
                        <p>Ссылка: https://localhost:8002</p>
                    </div>
                    <div className="popup_content">


                    </div>

                </div>
            </div>
        </div>
    );
}

export default PopupShare;