import React, { useState, useEffect } from "react";

function Menu() {
    const menuItems = [
        { label: "👻 Мои работы", href: "#myWork", id: "myWork" },
        { label: "👽 Мой опыт", href: "#myLevel", id: "myLevel" },
        { label: "💡 Мой стэк", href: "#byMe", id: "byMe" },
        { label: "🤖 Мои контакты", href: "#myContacts", id: "myContacts" },
        { label: "💊 Мои проекты", href: "#myProjects", id: "myProjects" },
    ];

    const [activeElement, setActiveElement] = useState(null);

    const handleClick = (id) => {
        setActiveElement(id);
    };

    useEffect(() => {
        if (activeElement) {
            const activeSection = document.getElementById(activeElement);
            if(document.querySelector('.active-content'))
            {
                document.querySelector('.active-content').classList.remove("active-content");
            }
            if (activeSection) {
                activeSection.classList.add("active-content");

                // Убираем выделение через 0.5 секунды
                const timeout = setTimeout(() => {
                    activeSection.classList.remove("active-content");
                }, 500);

                // Чистим таймер при смене активного элемента
                return () => clearTimeout(timeout);
            }
        }
    }, [activeElement]);

    return (
        <div>
            <div className="container-menu">
                {menuItems.map((item, index) => (
                    <a
                        href={item.href}
                        key={index}
                        className={`menu-element ${activeElement === item.id ? "active" : ""}`}
                        onClick={() => handleClick(item.id)}
                    >
                        {item.label}
                    </a>
                ))}
            </div>
        </div>
    );
}

export default Menu;
